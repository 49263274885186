import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import WaveD from "../components/WaveD"
import "../styles/styles.scss"
import Seo from "../components/Seo"

const Prices = () => {
  const yes = useStaticQuery(graphql`
    query {
      success: file(relativePath: { eq: "ticker.png" }) {
        childImageSharp {
          fixed(width: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      error: file(relativePath: { eq: "error.png" }) {
        childImageSharp {
          fixed(width: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Prices"
        description="The band academy prices reflect the quality of tuition we offer. Have a look at the current options we have available"
      />
      <WaveD />

      <section className="prices-global-wrap">
        <h1>The Band Academy Prices</h1>
        <h2>The Band Academy</h2>
        <p>
          The prices shown below are on a per month basis. Payment is taken via
          standing order meaning that you are in total control of your payments
          and can cease attending at anytime.
        </p>
        <div className="prices-wrap">
          <div className="price price-one">
            <h2>Starter</h2>

            <p className="price-description">
              {" "}
              A 1.5 hour Session. Perfect for new starters.
            </p>

            <div className="options">
              <ul>
                <li>Takes Place Every Week</li>
                <li>18:30pm - 20:00pm</li>
                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
                <li>Live Rehearsal</li>
                <li>Weekly 121 Coaching</li>
              </ul>
              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.error.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.error.childImageSharp.fixed}
                  />
                </li>

                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.error.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £65 per month</p>
          </div>

          <div className="price price-two">
            <h2>Performer</h2>

            <p className="price-description">
              {" "}
              A 3 hour Session. Perfect for rapid improvement.
            </p>

            <div className="options">
              <ul>
                <li>Takes Place Every Week</li>

                <li>18:30pm - 21:30pm</li>
                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
                <li>Live Rehearsal</li>
                <li>Weekly 121 Coaching</li>
              </ul>

              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.error.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £105 per month</p>
          </div>

          <div className="price price-three">
            <h2>Pro</h2>

            <p className="price-description">
              3 hour session + 121 coaching. Perfect for the fanatics!!!
            </p>

            <div className="options">
              <ul>
                <li>Takes Place Every Week</li>

                <li>18:30pm - 21:30pm</li>
                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
                <li>Live Rehearsal</li>
                <li>Weekly 121 Coaching</li>
              </ul>
              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £185 per month</p>
          </div>
        </div>
      </section>
      <section className="prices-global-wrap coaches-global-wrap">
        <h2>121 Coaching</h2>
        <p>
          All one to one coaching sessions are discounted if you are a member of
          The Band Academy. Prices quoted below exclude this discount. Pay as
          you go can be paid in whatever way works for you. Fortnightly and
          Weekly sessions are paid for via standing order.
        </p>
        <div className="prices-wrap">
          <div className="price price-one">
            <h2>Pay as you go</h2>
            <div className="options">
              <ul>
                <li>Flexible Times</li>
                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
              </ul>
              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.error.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £35 per session</p>
          </div>

          <div className="price price-two">
            <h2>Fortnightly</h2>
            <div className="options">
              <ul>
                <li>Coaching Fortnightly</li>

                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
              </ul>

              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £65 fortnightly</p>
          </div>

          <div className="price price-three">
            <h2>
              Weekly Lessons<br></br>
            </h2>
            <div className="options">
              <ul>
                <li>Coaching Every Week</li>
                <li>Technique</li>
                <li>Theory</li>
                <li>Songs</li>
                <li>Online Materials</li>
                <li>Member Videos</li>
              </ul>
              <ul>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
                <li>
                  <Img
                    width="20"
                    height="20"
                    fixed={yes.success.childImageSharp.fixed}
                  />
                </li>
              </ul>
            </div>
            <p>Cost: £105 per month</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Prices
