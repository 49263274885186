import React from "react"

const WaveD = () => {
  return (
    <div className="waveContainer">
      <div className="svgWrap">
        <svg width="100%" height="450px">
          <path
            className="wave"
            d="M-13,60S88.726,345.069,1346.98,152.505C1914.56,65.643,2000,190.311,2000,190.311V497.587L-13,500V60Z"
          />
        </svg>
      </div>
    </div>
  )
}

export default WaveD
